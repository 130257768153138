export interface HeaderNavigationItem {
  title: string
  href: string
  isEmbeddedAppLink: boolean
}

export const accountLinks: Record<'relish' | 'guest', HeaderNavigationItem[]> = {
  relish: [
    {
      title: 'My account',
      href: '/profile',
      isEmbeddedAppLink: true
    },
    {
      title: 'Coupons',
      href: '/profile',
      isEmbeddedAppLink: true
    }
  ],
  guest: [
    {
      title: 'Sign in',
      href: '/sign-in&source=marketing-site',
      isEmbeddedAppLink: true
    },
    {
      title: 'Join relish',
      href: '/sign-up&source=marketing-site',
      isEmbeddedAppLink: true
    }
  ]
}
